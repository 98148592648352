import React, {useState, useEffect} from 'react';
import "./style.styl";

export default function DigitalMarketingPage(props) {
    return (
        <div className="container draft-view">
           <h1>Digital Marketing</h1>
           <p className="text-muted">The main goal of digital marketing is to connect your business with potential customers in the digital world. This helps you with sales, reputation management, and the attraction of new customers online.</p>
           <p style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">We provide our clients exceptional service in the following areas: Search Engine Optimization, Social Media Management, Email Marketing Campaigns, Online Reputation Management, Digital Public Relations, Influencer Marketing, Content Curation and Creation, and B2B Digital Marketing Programs. Tap into Wescale for your digital marketing. Our brand tenders will mix a potent cocktail that will have your customers coming back for more.</p><p style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Wescale marketing specialists know how to utilize digital advantages to best help your business.</p>
           <p className="text-muted"></p>
           <h2><font face="Poppins, Helvetica, sans-serif">What is digital marketing?</font></h2>
           <p className="text-muted"><font face="Poppins, Helvetica, sans-serif">Due to the constant increase in time that people are spending online, these days all businesses should be utilizing digital marketing. Digital-marketing is the promotion of services and products using technologies mainly on the Internet.</font></p>
           <p className="text-muted"><font face="Poppins, Helvetica, sans-serif">The main advantage of digital marketing is the ability to use ‘pull’ marketing strategies. In other words, digital marketing is an effective way to encourage customers to seek out your product or company on their own. Digital marketing also allows you to convert ‘push’ marketing strategies into ‘pull’ marketing strategies. For example, a customer will see your ad only when he uses a search engine. You don’t annoy your potential customer.</font></p>
           <p className="text-muted"><font face="Poppins, Helvetica, sans-serif">In short, the digital world is a great source of customers that you should use to grow your business.</font></p>
           <p className="text-muted"></p>
           <h2 style={{fontFamily: 'Poppins, Helvetica, sans-serif'}}>Our Approach to Digital Marketing</h2><p style={{fontFamily: 'Poppins, Helvetica, sans-serif'}} className="text-muted">Our digital marketing services are driven not only by passion but also a driving desire to deliver exceptional sales conversions and ROI for our clients. We invest a substantial amount of time understanding our clients’ benchmarks of success and KPI’s and craft smart digital strategies to gain maximum market share in the digital universe.</p>
           <p className="text-muted"></p>
           <h2 ><font face="Poppins, Helvetica, sans-serif">Wescale Digital Marketing process</font></h2>
           <p className="text-muted"></p>
           <h5 >1. Research</h5>
           <p style={{}} className="text-muted"><font face="Poppins, Helvetica, sans-serif">The main goal of research to determine values and solutions for your customers. These help to connect people with your products and services. It also will create a message to attract your customers and make them more likely to buy from your company.</font></p>
           <p className="text-muted"></p>
           <h5>2. Plan</h5><p  className="text-muted"><font face="Poppins, Helvetica, sans-serif">A step-by-step plan will help you allocate resources and understand what we will be doing to achieve your goals.</font></p>
           <p className="text-muted"></p>
           <h5>3. Test</h5>
           <p className="text-muted">We are always ready to optimize the strategy, therefore using tests is the one way to visualize the outcome before actually launching the strategy.</p>
           <p className="text-muted"></p>
           <h5>4. Implement</h5>
           <p  className="text-muted">After the test, we implement effective solutions, as well as continue to optimize marketing your campaign.</p>
           <p className="text-muted"></p>
           <h5>5. Measure</h5>
           <p className="text-muted">We create milestones to measure that the investments and strategies are yielding expected results. Working with Wescale means more leads and sales growth.</p>
           <p className="text-muted"></p>
           <h5>6. Next level</h5>
           <p  className="text-muted">Your business will have changed and grown, which means that in order to move forward and continue this growth, you will need a new strategy to take your business one step further.</p><p  className="text-muted"></p><p  className="text-muted">Digital marketing is the most effective way to market a business today and for the foreseeable future. You need a reliable partner to help you promote your business in the digital world, and Wescale is your solution.<br /></p>

        </div>

    );
}
